import get from 'lodash.get';

import { cardTextVideo } from '@DTO/sections/CardTextVideo';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';

import { IFashionSection } from '@components/sections/FashionSection/FashionSection.d';

export const fashionSection = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const videoType = get(data, 'fieldVariantVideoText', 'url');

  const mappedData: IFashionSection = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    video: {
      poster: get(data, 'fieldPoster')
        ? {
            alt: get(data, 'fieldPoster.alt', ''),
            url: get(data, 'fieldPoster.url', ''),
            title: get(data, 'fieldPoster.title', ''),
            webp: get(data, 'fieldPoster.derivative.url', ''),
          }
        : undefined,
      url:
        videoType === 'url'
          ? get(data, 'fieldVideoUrl.url.path', undefined)
          : get(data, 'fieldVideo.entity.url', undefined),
      schemaData: get(data, 'fieldName', undefined)
        ? {
            name: get(data, 'fieldName', ''),
            duration: get(data, 'fieldVideoDuration', ''),
            description: get(data, 'fieldVideoDescription', ''),
            uploadDate: get(data, 'entityCreated', ''),
          }
        : undefined,
    },
    textAndVideo: get(data, 'fieldCardTextVideo', undefined)
      ? cardTextVideo(data, 'fieldCardTextVideo')
      : undefined,
    contextImageWithCaption: get(data, 'fieldContextImageWithCaption', undefined)
      ? contextImageWithCaption(data, 'fieldContextImageWithCaption')
      : undefined,
  };

  return mappedData;
};
