import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const fashionSiteData = () => {
  const data = useStaticQuery(graphql`
    query fashionSiteQuery {
      drupalData {
        nodeQuery(
          filter: { conditions: { field: "type", value: "industries_page" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_NodeIndustriesPage {
              entityLabel

              fieldAccessories {
                entity {
                  ...ParagraphAccessories
                }
              }
              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldConversion2Cta {
                entity {
                  ...ParagraphConversion2Cta
                }
              }
              fieldFaq {
                entity {
                  ...ParagraphFaq
                }
              }
              fieldFashionCardSmall {
                entity {
                  ...ParagraphFashionCardSmall
                }
              }
              fieldFashionSection {
                entity {
                  ...ParagraphFashionSection
                }
              }
              fieldGallery {
                entity {
                  ...ParagraphGallery
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }

              fieldCategoryA {
                entity {
                  title
                  path {
                    alias
                  }
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
