import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { FashionSiteDTO } from '@DTO/FashionSiteDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface FashionSiteProps {
  pageContext?: TemplatePageContextData;
}

const FashionSite = ({ pageContext }: FashionSiteProps) => {
  const fashionSite = FashionSiteDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(fashionSite.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={fashionSite.metaData}
        breadcrumbs={fashionSite.breadcrumbs}
        stickedForm={fashionSite.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={fashionSite.langPath}
      >
        <PageComponents components={fashionSite.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default FashionSite;
