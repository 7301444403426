import get from 'lodash.get';

export const accessories = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    boxes: get(data, 'fieldAccessoriesMedia', []).map((item) => {
      const selfHostedVideo = get(item, 'entity.fieldVideo.entity.url', false);

      return {
        description: get(item, 'entity.fieldDescription.processed', ''),
        image: {
          alt: get(item, 'entity.fieldImage.alt', ''),
          title: get(item, 'entity.fieldImage.title', ''),
          url: get(item, 'entity.fieldImage.url', ''),
          webp: get(item, 'entity.fieldImage.derivative.url', ''),
        },
        imageOrVideo: get(item, 'entity.fieldSelectImageOrVideo', ''),
        title: get(item, 'entity.fieldTitle', ''),
        video: selfHostedVideo
          ? {
              title: '',
              url: selfHostedVideo,
            }
          : {
              title: get(item, 'entity.fieldVideoUrl.title', ''),
              url: get(item, 'entity.fieldVideoUrl.url.path', ''),
            },
        poster: get(item, 'entity.fieldPoster.derivative.url', ''),
        schemaData: get(data, 'entity.fieldName', undefined)
          ? {
              name: get(data, 'entity.fieldName', ''),
              duration: get(data, 'entity.fieldVideoDuration', ''),
              description: get(data, 'entity.fieldVideoDescription', ''),
              uploadDate: get(data, 'entity.entityCreated', ''),
            }
          : undefined,
      };
    }),
    linkBox: {
      url: get(data, 'fieldAccessoriesUrl.entity.fieldLinkCta.url.path', ''),
      title: get(data, 'fieldAccessoriesUrl.entity.fieldLinkCta.title', ''),
      description: get(data, 'fieldAccessoriesUrl.entity.fieldDescription.processed', ''),
    },
  };
  return mappedData;
};
