import get from 'lodash.get';

export const cardTextImage = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    headlineImage: get(data, 'fieldHeadlineImage') ? {
      alt: get(data, 'fieldHeadlineImage.alt', ''),
      title: get(data, 'fieldHeadlineImage.title', ''),
      url: get(data, 'fieldHeadlineImage.url', ''),
      webp: get(data, 'fieldHeadlineImage.derivative.url', ''),
    } : undefined,
    headlineImageMobile: get(data, 'fieldHeadlineImageMobile') ? {
      alt: get(data, 'fieldHeadlineImageMobile.alt', ''),
      title: get(data, 'fieldHeadlineImageMobile.title', ''),
      url: get(data, 'fieldHeadlineImageMobile.url', ''),
      webp: get(data, 'fieldHeadlineImageMobile.derivative.url', ''),
    } : undefined,
    firstCardSide:
      get(data, 'fieldVariant', '1') === '1' ? 'left' : 'right',
    cards: get(data, 'fieldTextAndImage', []).map((card) => ({
      headline: get(card, 'entity.fieldHeadline.processed', ''),
      description: get(card, 'entity.fieldDescription.processed', ''),
      image: get(card, 'entity.fieldImage', undefined)
        ? {
            alt: get(card, 'entity.fieldImage.alt', ''),
            title: get(card, 'entity.fieldImage.title', ''),
            url: get(card, 'entity.fieldImage.url', ''),
            webp: get(card, 'entity.fieldImage.derivative.url'),
          }
        : undefined,
      video: get(card, 'entity.fieldVideo.entity.url', undefined),
      videoUrl: get(card, 'entity.fieldVideoUrl.url.path', undefined),
      label: get(card, 'entity.fieldLabel', undefined)
        ? {
            title: get(card, 'entity.fieldLabel', ''),
            url: get(card, 'entity.fieldLabelLink.url.path', ''),
            color: get(card, 'entity.fieldLabelColor', null),
          }
        : undefined,
      cta: get(card, 'entity.fieldLinkCta', undefined)
        ? {
            label: get(card, 'entity.fieldLinkCta.title', ''),
            url: get(card, 'entity.fieldLinkCta.url.path', ''),
          }
        : undefined,
      imageOrVideo: get(card, 'entity.fieldSelectImageOrVideo', 'image'), // return: 'image' | 'video'
      poster: get(card, 'entity.fieldPoster', undefined) ? {
        alt: get(card, 'entity.fieldPoster.alt', ''),
        title: get(card, 'entity.fieldPoster.title', ''),
        url: get(card, 'entity.fieldPoster.url', ''),
        webp: get(card, 'entity.fieldPoster.derivative.url'),
      } : undefined,
      schemaData: get(card, 'entity.fieldName', undefined) ? {
        name: get(card, 'entity.fieldName', ''),
        duration: get(card, 'entity.fieldVideoDuration', ''),
        description: get(card, 'entity.fieldVideoDescription', ''),
        uploadDate: get(card, 'entity.entityCreated', '')
      } : undefined,
      slider: get(card, 'entity.fieldSliderParagraph', []).map((slide) => ({
        image: {
          alt: get(slide, 'entity.fieldSlider.alt', ''),
          title: get(slide, 'entity.fieldSlider.title', ''),
          url: get(slide, 'entity.fieldSlider.url', ''),
          webp: get(slide, 'entity.fieldSlider.derivative.url'),
        },
        video: get(slide, 'entity.fieldVideo.entity.url') ? {
          description: get(slide, 'entity.fieldVideo.description', ''),
          url: get(slide, 'entity.fieldVideo.entity.url', ''),
          schemaData: get(card, 'entity.fieldName', undefined) ? {
            name: get(card, 'entity.fieldName', ''),
            duration: get(card, 'entity.fieldVideoDuration', ''),
            description: get(card, 'entity.fieldVideoDescription', ''),
            uploadDate: get(card, 'entity.entityCreated', '')
          } : undefined,
        } : undefined,
      }))
    })),
  }
}