import get from 'lodash.get';

export const fashionCardSmall = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, `fieldHeadline.processed`, ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    cards: get(data, `fieldFashionCardSmallItem`, []).map(card => ({
      title: get(card, `entity.fieldTitle`, ''),
      copy: get(card, `entity.fieldCopy.processed`, ''),
      image: get(card, `entity.fieldImage`) ? {
        alt: get(card, `entity.fieldImage.alt`, ''),
        webp: get(card, `entity.fieldImage.derivative.url`, ''),
        title: get(card, `entity.fieldImage.title`, ''),
        url: get(card, `entity.fieldImage.url`, ''),
      } : undefined,
      learnMoreLink: get(card, `entity.fieldLearnMoreLink`) ? {
        title: get(card, `entity.fieldLearnMoreLink.title`, ''),
        url: get(card, `entity.fieldLearnMoreLink.url.path`, ''),
      } : undefined,
      bookADemoLink: get(card, `entity.fieldBookADemoLink`) ? {
        title: get(card, `entity.fieldBookADemoLink.title`, ''),
        url: get(card, `entity.fieldBookADemoLink.url.path`, ''),
      } : undefined,
    })),
    lastCard: get(data, `fieldFashionCardSmallLast`) ? {
      title: get(data, `fieldFashionCardSmallLast.entity.fieldTitle`, ''),
      copy: get(data, `fieldFashionCardSmallLast.entity.fieldCopy.processed`, ''),
      link: get(data, `fieldFashionCardSmallLast.entity.fieldLinkCta`) ? {
        title: get(data, `fieldFashionCardSmallLast.entity.fieldLinkCta.title`),
        url: get(data, `fieldFashionCardSmallLast.entity.fieldLinkCta.url.path`)
      } : undefined,
    } : undefined,
  };
  return mappedData;
}
