import get from 'lodash.get';

export const gallery = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    title: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    items: get(data, 'fieldGalleryItem', []).map((galleryItem) => {
      return {
        video: {
          alt: get(galleryItem, 'entity.fieldVideo.description', ''),
          title: get(galleryItem, 'entity.fieldVideo.entity.filename', ''),
          url: get(galleryItem, 'entity.fieldVideo.entity.url', ''),
          poster: get(galleryItem, 'entity.fieldPoster', undefined)
            ? {
                url: get(galleryItem, 'entity.fieldPoster.url', ''),
                alt: get(galleryItem, 'entity.fieldPoster.alt', ''),
                title: get(galleryItem, 'entity.fieldPoster.title', ''),
                webp: get(galleryItem, 'entity.fieldPoster.derivative.url', ''),
              }
            : undefined,
          schemaData: get(galleryItem, 'entity.fieldName', undefined)
            ? {
                name: get(galleryItem, 'entity.fieldName', ''),
                duration: get(galleryItem, 'entity.fieldVideoDuration', ''),
                description: get(galleryItem, 'entity.fieldVideoDescription', ''),
                uploadDate: get(galleryItem, 'entity.entityCreated', ''),
              }
            : undefined,
        },
        option: get(galleryItem, 'entity.fieldGalleryOption', ''),
        code: get(galleryItem, 'entity.fieldCode', ''),
        image: {
          url: get(galleryItem, 'entity.fieldImage.url', ''),
          alt: get(galleryItem, 'entity.fieldImage.alt', ''),
          title: get(galleryItem, 'entity.fieldImage.title', ''),
          webp: get(galleryItem, 'entity.fieldImage.derivative.url', ''),
        },
      };
    }),
  };
};
